import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
  clientToken: "pub18f0030ed116dc16041ea45601a5a147",
  site: "datadoghq.com",
  service: "monkeys-web-app",
  env: process.env.NEXT_PUBLIC_VERCEL_ENV,
  version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  forwardErrorsToLogs: true,
  useSecureSessionCookie: true,
  sampleRate: 100,
});

// datadogRum.init({
//   applicationId: "9a3c044b-4e08-493e-b440-70272967863f",
//   clientToken: "pub18f0030ed116dc16041ea45601a5a147",
//   site: "datadoghq.com",
//   service: "monkeys-web-app",
//   env: process.env.NEXT_PUBLIC_VERCEL_ENV,
//   version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
//   sampleRate: 100,
//   trackInteractions: true,
//   defaultPrivacyLevel: "mask-user-input",
//   useSecureSessionCookie: true,
// });

// datadogRum.startSessionReplayRecording();
