/** @type {import('next-seo').DefaultSeoProps} */
const defaultSEOConfig = {
  title: "NoEvilMonkey",
  titleTemplate: "%s | NoEvilMonkey",
  defaultTitle: "NoEvilMonkey",
  description:
    "NoEvilMonkeys is a collection of 9999 randomly generated NFTs on the Ethereum blockchain.",
  canonical: "https://mint.seenoevilmonkeyart.com",
  openGraph: {
    url: "https://mint.seenoevilmonkeyart.com",
    title: "NoEvilMonkey NFT-Collection",
    description:
      "NoEvilMonkeys is a collection of 9999 randomly generated NFTs on the Ethereum blockchain.",
    images: [
      {
        url: "https://seenoevilmonkeyart.com/wp-content/uploads/2022/04/NoEvilMonkeys-Mission_PFP-800px.png",
      },
    ],
    site_name: "NoEvilMonkey",
  },
  twitter: {
    handle: "@NoEvilMonkeys_",
    cardType: "summary_large_image",
  },
};

export default defaultSEOConfig;
