/* eslint-disable react/jsx-props-no-spreading */
import { ChakraProvider } from "@chakra-ui/react";
import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import Head from "next/head";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/staatliches/400.css";

import defaultSEOConfig from "../../next-seo.config";
import customTheme from "styles/customTheme";
import "styles/globals.css";
import { Provider, chain, Connector } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { WalletLinkConnector } from "wagmi/connectors/walletLink";
import { providers } from "ethers";

import "lib/datagod";
import "lib/amplitude";

// API key for Ethereum node
// Two popular services are Infura (infura.io) and Alchemy (alchemy.com)
const infuraId = process.env.NEXT_PUBLIC_INFURA_ID;
const alchemyId = process.env.NEXT_PUBLIC_ALCHEMY_API_KEY;

// Pick chains
const chains = [chain.mainnet]; //  [...defaultChains, chain.localhost];
const defaultChain = chain.mainnet;

// Set up connectors
type ConnectorsConfig = { chainId?: number };
const connectors = ({ chainId }: ConnectorsConfig): Connector[] => {
  if (chainId === chain.localhost.id) {
    return [
      new InjectedConnector({
        chains: [
          {
            id: 1337,
            name: "localhost",
            testnet: false,
            rpcUrls: ["http://127.0.0.1:8545"],
          },
        ],
        options: { shimDisconnect: true },
      }),
    ];
  }

  const rpcUrl =
    chains.find((x) => x.id === chainId)?.rpcUrls?.[0] ??
    chain.mainnet.rpcUrls[0];

  return [
    new InjectedConnector({
      chains,
      options: { shimDisconnect: true },
    }),
    new WalletConnectConnector({
      options: {
        infuraId,
        qrcode: true,
      },
    }),
    new WalletLinkConnector({
      options: {
        appName: "NoEvilMonkey",
        jsonRpcUrl: `${rpcUrl}/${infuraId}`,
      },
    }),
  ];
};

// Set up providers
type ProviderConfig = { chainId?: number; connector?: Connector };
const isChainSupported = (chainId?: number) =>
  chains.some((x) => x.id === chainId);

const provider = ({ chainId, connector }: ProviderConfig) => {
  if (chainId === chain.localhost.id) {
    return new providers.JsonRpcProvider();
  }
  return new providers.AlchemyProvider(
    isChainSupported(chainId) ? chainId : defaultChain.id,
    alchemyId
  );
};

const webSocketProvider = ({ chainId }: ProviderConfig) => {
  if (chainId === chain.localhost.id) {
    return undefined;
  }

  return isChainSupported(chainId)
    ? new providers.AlchemyWebSocketProvider(chainId, alchemyId)
    : undefined;
};

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <Provider
      autoConnect={true}
      connectors={connectors}
      provider={provider}
      webSocketProvider={webSocketProvider}
    >
      <ChakraProvider theme={customTheme}>
        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
          />
        </Head>
        <DefaultSeo {...defaultSEOConfig} />
        <Component {...pageProps} />
      </ChakraProvider>
    </Provider>
  );
};

export default MyApp;
